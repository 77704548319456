import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MediaItem } from '../../../shared/interfaces/media-item';
import { LoadingService } from '../../../core/services/loading.service';
import { MakerMediaService } from '../../../core/services/maker-media.service';

@Component({
  selector: 'ukata-create-narration',
  templateUrl: './create-narration.component.html',
  styleUrls: ['./create-narration.component.scss'],
})
export class CreateNarrationComponent implements OnInit {
  mediaTitle: string = '';
  mediaContent: string = '';
  ssmlContent: string = '';
  speechRate: number = 0;
  useSSML: boolean = false;
  defaultLanguage: string = 'en-US';
  supportedLanguages: string[] = ['en-US', 'vi-VN', 'ja-JP'];

  @Input()
  checkWithSTT: boolean = false;

  @Input()
  fullCheck: boolean = false;

  @Input()
  skipHashCheck: boolean = false;
  mediaItems: MediaItem[] = [];
  voice: string = 'en-US-DavisNeural';
  style: string = 'cheerful';

  constructor(
    public loadingService: LoadingService,
    private makerMediaService: MakerMediaService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  createNarrationAudio() {
    const obs = this.useSSML
      ? this.makerMediaService.createAudioFromSSML({
          title: this.mediaTitle,
          defaultLanguage: this.defaultLanguage,
          content: this.ssmlContent,
          speechRate: this.speechRate,
        })
      : this.makerMediaService.createSingleAudio(
          {
            title: this.mediaTitle,
            speaker: {
              text: this.mediaContent,
              speaker_id: this.voice,
              style: this.style,
            },
          },
          this.checkWithSTT,
          this.fullCheck,
          this.skipHashCheck
        );

    obs.subscribe({
      next: (data: MediaItem) => {
        console.log('data ', data);
        this.mediaItems.unshift(data);
        this.mediaTitle = '';
        this.mediaContent = '';
        this.cd.detectChanges();
      },
      error: (error) => {},
    });
  }
}
