<div>
  <h3>Create audio</h3>
  <nz-switch
    [(ngModel)]="useSSML"
    nzCheckedChildren="Use SSML"
    nzUnCheckedChildren="Use text"
  ></nz-switch>
  <nz-form-item>
    <nz-form-label>Media title</nz-form-label>
    <input [(ngModel)]="mediaTitle" nz-input />
  </nz-form-item>
  <div *ngIf="!useSSML; else ssmlTemplate">
    <nz-form-item>
      <nz-form-label>Media content</nz-form-label>
      <textarea [(ngModel)]="mediaContent" nz-input rows="5"></textarea>
    </nz-form-item>

    <ukata-tts-style-picker
      (onStyleChange)="style = $event"
      [style]="style"
    ></ukata-tts-style-picker>
    <ukata-tts-voice-picker
      (onVoiceChange)="voice = $event"
      [voice]="voice"
    ></ukata-tts-voice-picker>
    <p></p>

    <div>
      <div>
        <label [(ngModel)]="checkWithSTT" nz-checkbox>Check with STT</label>
        <label [(ngModel)]="fullCheck" [nzDisabled]="!checkWithSTT" nz-checkbox
          >Full check</label
        >
      </div>
      <div>
        <label [(ngModel)]="skipHashCheck" nz-checkbox
          >Skip check existing (hash)</label
        >
      </div>
    </div>
    <br />
  </div>
  <button (click)="createNarrationAudio()" nz-button nzType="primary">
    Create
  </button>
</div>

<ng-template #ssmlTemplate>
  <nz-form-item>
    <nz-form-label>SSML content</nz-form-label>
    <textarea placeholder="could be text or SSML, text will be parsed" [(ngModel)]="ssmlContent" nz-input rows="5"></textarea>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label>Default Language</nz-form-label>
    <nz-select [(ngModel)]="defaultLanguage">
      <nz-option
        *ngFor="let language of supportedLanguages"
        [nzValue]="language"
        [nzLabel]="language"
      ></nz-option>
    </nz-select>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label>Speech Rate</nz-form-label>
    <input nz-input type="number" min="-100" max="100" [(ngModel)]="speechRate" />
  </nz-form-item>
</ng-template>
